(function () {

    const colors = {
        green: '#3EBB9F',
        lime: '#BED477',
        yellow: '#FBAF17',
        red: '#E71855',
        blue: '#93D0D0'
    }

    $('.hero-slider').on('init', function (slick) {
        const heroSlides = document.querySelectorAll('.hero-slider__slide');

        heroSlides.forEach((slide, index) => {
            const colorIndex = index % Object.keys(colors).length;
            slide.setAttribute('data-color', Object.keys(colors)[colorIndex])
        })
    })

    $('.hero-slider').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000
    })


    $('.hero-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        const currentColor = slick.$slides.get(nextSlide).getAttribute('data-color')
        document.documentElement.style.setProperty('--main-color', colors[currentColor]);
    })
})()