const paper = require('paper');

(function () {

    const follower = document.getElementById('follower');
    const dot = document.getElementById('follower-dot');
    //
    // document.addEventListener('mousemove', (event) => {
    //     const x = event.clientX;
    //     const y = event.clientY;
    //
    //     setTimeout(function () {
    //         dot.style.left = x + 'px';
    //         dot.style.top = y + 'px';
    //     }, 190);
    //
    //     setTimeout(function () {
    //         follower.style.left = x + 'px';
    //         follower.style.top = y + 'px';
    //     }, 200);
    //
    // });

    let clientX = -100;
    let clientY = -100;
    const innerCursor = document.querySelector(".cursor--small");

    const initCursor = () => {
        // add listener to track the current mouse position
        document.addEventListener("mousemove", e => {
            clientX = e.clientX;
            clientY = e.clientY;
        });

        // transform the innerCursor to the current mouse position
        // use requestAnimationFrame() for smooth performance
        const render = () => {
            innerCursor.style.transform = `translate(${clientX}px, ${clientY}px)`;
            // if you are already using TweenMax in your project, you might as well
            // use TweenMax.set() instead
            // TweenMax.set(innerCursor, {
            //   x: clientX,
            //   y: clientY
            // });

            requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
    };

    initCursor();

    let lastX = 0;
    let lastY = 0;
    let isStuck = false;
    let showCursor = false;
    let group, stuckX, stuckY, fillOuterCursor;

    const initCanvas = () => {
        const canvas = document.querySelector(".cursor--canvas");
        const shapeBounds = {
            width: 60,
            height: 60
        };
        paper.setup(canvas);
        const fillColor = "rgba(220, 221, 222, 0.5)";
        const segments = 8;
        const radius = 23;

        // the base shape for the noisy circle
        const polygon = new paper.Path.RegularPolygon(
            new paper.Point(0, 0),
            segments,
            radius
        );
        polygon.fillColor = fillColor;
        polygon.smooth();
        group = new paper.Group([polygon]);
        group.applyMatrix = false;


        // function for linear interpolation of values
        const lerp = (a, b, n) => {
            return (1 - n) * a + n * b;
        };

        // function to map a value from one range to another range
        const map = (value, in_min, in_max, out_min, out_max) => {
            return (
                ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
            );
        };

        // the draw loop of Paper.js
        // (60fps with requestAnimationFrame under the hood)
        paper.view.onFrame = event => {
            // using linear interpolation, the circle will move 0.2 (20%)
            // of the distance between its current position and the mouse
            // coordinates per Frame
            if (!isStuck) {
                // move circle around normally
                lastX = lerp(lastX, clientX, 0.1);
                lastY = lerp(lastY, clientY, 0.1);
                group.position = new paper.Point(lastX, lastY);
            } else if (isStuck) {
                // fixed position on a nav item
                lastX = lerp(lastX, stuckX, 0.1);
                lastY = lerp(lastY, stuckY, 0.1);
                group.position = new paper.Point(lastX, lastY);
            }

            if (isStuck && polygon.bounds.width < shapeBounds.width) {
                // scale up the shape
                polygon.scale(1.08);
                innerCursor.classList.add('hovered')
            } else if (!isStuck && polygon.bounds.width > 46) {
                // scale down the shape
                polygon.scale(0.92);
                innerCursor.classList.remove('hovered')
            }

            polygon.smooth();
        };

    }

    initCanvas();

    const initHovers = () => {

        // find the center of the link element and set stuckX and stuckY
        // these are needed to set the position of the noisy circle
        const handleMouseEnter = e => {
            const navItem = e.currentTarget;
            const navItemBox = navItem.getBoundingClientRect();
            stuckX = Math.round(navItemBox.left + navItemBox.width / 2);
            stuckY = Math.round(navItemBox.top + navItemBox.height / 2);
            isStuck = true;
        };

        // reset isStuck on mouseLeave
        const handleMouseLeave = () => {
            isStuck = false;
        };

        const buttons = document.querySelectorAll('a:not(.link-with-icon, .image-button), .burger');
        buttons.forEach(item => {
            item.addEventListener('mouseenter', handleMouseEnter);
            item.addEventListener("mouseleave", handleMouseLeave);
        })

        const cta = document.querySelectorAll('.cta, .image-button');
        cta.forEach(item => {
            item.addEventListener('mouseenter', function (e) {
                handleMouseEnter(e);
                item.classList.add('hovered')
                innerCursor.classList.add('cta-hovered')
            })
            item.addEventListener('mouseleave', function (e) {
                handleMouseLeave();
                item.classList.remove('hovered')
                innerCursor.classList.remove('cta-hovered')
            })
        })

        function iconTemplate(icon) {
            return `<svg>
              <use xlink:href="assets/svg-sprite/sprite.svg#${icon}"/>
            </svg>`
        }

        const iconLinks = document.querySelectorAll('.link-with-icon');
        iconLinks.forEach(item => {
            item.addEventListener('mouseenter', function (e) {
                const icon = e.currentTarget.getAttribute('data-icon');
                follower.innerHTML = iconTemplate(icon)
                handleMouseEnter(e);
                innerCursor.classList.add('icon-hovered')
            })
            item.addEventListener('mouseleave', function (e) {
                handleMouseLeave();
                follower.innerHTML = ''
                innerCursor.classList.remove('icon-hovered')
            })
        })
    };

    initHovers();
})()